














import { Component, Vue, Prop } from 'vue-property-decorator';
import { Fragment } from 'vue-fragment';
import { FilterInput } from '@/shared/services/filter/filter.service';

import { DivisionModule } from '@/store/index';

@Component({
  components: {
    Fragment
  }
})
export default class DIVFilter extends Vue {
  @Prop() filter: FilterInput<string>;

  divisionModule = DivisionModule;

  divOptions: any = [{ value: null, text: 'All' }];

  constructor() {
    super();
  }

  created() {
    this.divOptions = [
      ...this.divOptions,
      ...DivisionModule.divisionsList.map(i => ({
        ...{
          value: i.abbr,
          text: i.name
        }
      }))
    ];
  }
}
